<template>
  <v-container fluid class="px-0">
    <PrivacyPolicyPage />
  </v-container>
</template>

<script>
import PrivacyPolicyPage from "@/components/layout/others/PrivacyPolicyPage";
export default {
  name: "PrivacyPolicy",
  components: { PrivacyPolicyPage }
};
</script>

<style scoped></style>
